import React from 'react';
import Main from '../components/pages/Main';
import PrivatePolicy from '../components/pages/private-policy';

const MapaDoSite = () => (
  <Main page="politica-de-privacidade">
    <PrivatePolicy />
  </Main>
);

export default MapaDoSite;
