import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import ContentWrap from '../atoms/ContentWrap';
import ListItem from '../atoms/contract/ListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    '& *': {
      fontFamily: theme.typography.fontFamily,
      color: theme.typography.body1.color,
      lineHeight: 1.51,
    },
    minHeight: '100vh',
    marginBottom: 64,

    '& a': {
      color: theme.palette.info.main,
    },
  },
  hero: {
    height: '550px',
    background: '#F8F9FA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '0px 0px 0px 100px',
    boxShadow: ' 0px 30px 70px 0px #3A35681A',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px',
    },

    '& .MuiTypography-h3': {
      width: '500px',
      textAlign: 'center',
      marginTop: 16,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  bodyContent: {
    marginTop: 140,
  },
  paragraph: {
    '& .MuiTypography-h3': {
      marginBottom: 24,
      marginTop: 24,
    },
  },
  limitedWidth: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  topic: {
    marginBottom: 16,
  },
  underline: {
    textDecoration: 'underline',
  },
}));

export default function PrivatePolicy() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.hero}>
        <Typography variant="h6">Política de privacidade</Typography>

        <Typography variant="h3">
          Nós levamos a integridade e a ética muito a sério
        </Typography>
      </div>
      <ContentWrap className={classes.bodyContent}>
        <div>
          <div className={clsx(classes.paragraph, classes.limitedWidth)}>
            <Typography variant="h3">Introdução</Typography>
            <Typography className={classes.topic} variant="body1">
              Na CONUBE, a privacidade e a segurança de nossos usuários e
              clientes é prioridade. Por isso, estamos sempre comprometidos com
              a transparência do tratamento de todos os dados pessoais. Em nossa
              Política de Privacidade, estabelecemos como é feita a coleta, uso
              e transferência de informações de clientes ou outras pessoas que
              acessam ou usam nosso site.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Ao entrar em nossos sites, você entende que coletamos e usamos
              suas informações pessoais nas formas descritas nesta Política, sob
              as normas da Lei n° 13.709/2018, das disposições consumeristas da
              Lei Federal 8078/1990 e as demais normas do ordenamento jurídico
              brasileiro aplicáveis.
            </Typography>
            <Typography>
              Caso tenha qualquer dúvida, estamos sempre à disposição pelo
              e-mail privacidade@conube.com.br. Envie uma mensagem com o assunto
              “Política de Privacidade” e entraremos em contato.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h3">Definições</Typography>
            <Typography className={classes.topic} variant="body1">
              CONUBE: Quando é mencionado o nome “CONUBE”, ou qualquer outra
              variação gramatical da primeira pessoa do plural, estamos nos
              referindo às empresas CONUBE CONTABILIDADE ONLINE LTDA. (CNPJ
              18.761.396/0001-55) e CONUBE TECNOLOGIA E SERVIÇOS LTDA. (CNPJ
              21.196.219/0001-88);
            </Typography>
            <Typography className={classes.topic} variant="body1">
              CLIENTE: pessoa física ou jurídica que concordou com as condições
              do Termo De Uso para utilização do nosso site e, também, quem
              contratou os serviços da CONUBE CONTABILIDADE ONLINE LTDA. (CNPJ
              18.761.396/0001-55), prestadora de dos serviços de assessoria
              online contábil;
            </Typography>
            <Typography className={classes.topic} variant="body1">
              CONTROLADOR: pessoa natural ou jurídica, de direito público ou
              privado, a quem competem as decisões referentes ao tratamento de
              dados pessoais;
            </Typography>
            <Typography className={classes.topic} variant="body1">
              DADOS PESSOAIS: qualquer informação sobre pessoa natural
              identificada ou identificável, não considerando dados anônimos.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              DADOS PESSOAIS SENSÍVEIS: dado pessoal sobre origem racial ou
              étnica, convicção religiosa, opinião política, filiação a
              sindicato ou organização de caráter religioso, filosófico ou
              político, dado referente à saúde ou à vida sexual, dado genético
              ou biométrico, quando vinculado a uma pessoa natural;
            </Typography>
            <Typography className={classes.topic} variant="body1">
              LGPD: Lei nº 13.709/2018, conhecida como a Lei Geral de Proteção
              de Dados;
            </Typography>
            <Typography className={classes.topic} variant="body1">
              OPERADOR DE DADOS PESSOAIS: pessoa natural ou jurídica, de direito
              público ou privado, que realiza o tratamento de dados pessoais em
              nome do controlador.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              SITE: plataformas de propriedade da CONUBE que possuem os
              seguintes domínios:
              <ListItem>
                <li>
                  Site oficial (Landing Page):
                  {' '}
                  <a href="https://www.conube.com.br" target="_blank" rel="noreferrer">conube.com.br</a>
                </li>
                <li>
                  Plataforma Dynamo:
                  {' '}
                  <a href={process.env.GATSBY_DYNAMO_LOGIN} target="_blank" rel="noreferrer">{process.env.GATSBY_DYNAMO_LOGIN}</a>
                </li>
                <li>
                  Plataforma Abrir Empresas:
                  {' '}
                  <a href="http://abrir-empresa.conube.com.br" target="_blank" rel="noreferrer">abrirempresa.conube.com.br</a>
                </li>
                <li>
                  Plataforma Troca de Contador:
                  {' '}
                  <a href="https://trocarcontador.conube.com.br" target="_blank" rel="noreferrer">trocarcontador.conube.com.br</a>
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              TERCEIRO(S): qualquer pessoa não pertencente na relação contratual
              formalizada entre a Conube e os Usuários;
            </Typography>
            <Typography className={classes.topic} variant="body1">
              TRATAMENTO: toda operação realizada com dados pessoais, como as
              que se referem a coleta, produção, recepção, classificação,
              utilização, acesso, reprodução, transmissão, distribuição,
              processamento, arquivamento, armazenamento, eliminação, avaliação
              ou controle da informação, modificação, comunicação,
              transferência, difusão ou extração;
            </Typography>
            <Typography className={classes.topic} variant="body1">
              USUÁRIO(S): quem faz uso da Plataforma Conube, incluindo clientes,
              parceiros, clientes em potencial e visitantes.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              VOCÊ: o Usuário
            </Typography>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="h3">Informações Gerais</Typography>
            <Typography className={classes.topic} variant="body1">
              Os nossos serviços somente devem ser utilizados por Você caso
              concorde com esta Política de Privacidade. Por favor, leia com
              atenção todos os termos previstos e, em caso de dúvidas, entre em
              contato conosco previamente no e-mail privacidade@conube.com.br,
              inserindo como assunto do e-mail “Política de Privacidade”.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Os nossos serviços são voltados, em sua maioria, para atender
              pessoas jurídicas. Assim, quando disponibilizamos os mesmos em
              razão de um contrato com Cliente pessoa jurídica, o Controlador
              dos dados pessoais de terceiros, como funcionários e partes
              relacionadas, inseridos na plataforma será o próprio Cliente.
              Nestas hipóteses, a CONUBE atuará apenas como Operadora do
              tratamento dos dados pessoais, executando o tratamento em nome do
              Controlador (o Cliente) e de acordo com as orientações por este
              transmitida. É o caso, por exemplo, de dados de terceiros que são
              inseridos pelo Usuário no Site para fins de emissão de notas
              fiscais.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Apesar de, na maioria dos casos, atuarmos como Operadora no
              tratamento dos dados pessoais, em algumas situações a CONUBE atua
              como Controladora. É o caso da coleta dos dados pessoais dos
              Clientes que sejam pessoas físicas, de representantes dos Clientes
              pessoas jurídicas, de visitantes do Site ou de Usuários vinculados
              às pessoas físicas. Em todos os casos, a CONUBE é a pessoa a quem
              compete as decisões referentes ao tratamento dos dados pessoais
              destes.
            </Typography>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="h3">Dados Pessoais Tratados</Typography>
            <Typography className={classes.topic} variant="body1">
              Inicialmente, informamos que ao preencher o formulário de cadastro
              e fornecer as informações à CONUBE, Você declara que as
              informações são verdadeiras e está de acordo com o processamento
              de dados cadastrais, conforme estabelecido nesta Política.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              A quantidade e os tipos de dados pessoais coletados variam
              conforme a natureza da relação que Você tem com a CONUBE.
              Coletaremos diferentes dados caso Você seja apenas um visitante do
              site, esteja solicitando uma proposta de prestação de serviços ou
              já fazendo uso dos serviços contratados, por exemplo.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Quando uma pessoa acessa o Site, é possível que alguns dados sejam
              coletados de forma automática, por meio de cookies, bem como que
              esta forneça alguns dados diretamente, preenchendo voluntariamente
              algumas informações. De forma automática, é possível a coleta dos
              seguintes dados: informações sobre os computadores, smartphones ou
              outros dispositivos que o usuário utiliza para acessar a
              plataforma, bem como o nome de domínio do seu provedor de acesso à
              internet, o endereço de protocolo IP utilizado para se conectar ao
              Sistema/software CONUBE, o tipo e versão do browser, o sistema
              operacional, dados de falhas fatais, o tempo médio gasto e as
              páginas visitadas no Site, as páginas ou o conteúdo visualizadas,
              as buscas por anúncios, e as páginas acessadas antes ou após
              utilizar nosso site, os horários de acesso e outras estatísticas
              relevantes para o fornecimento de serviço.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Quando uma pessoa acessa o Site, é possível que alguns dados sejam
              coletados de forma automática, por meio de cookies, bem como que
              esta forneça alguns dados diretamente, preenchendo voluntariamente
              algumas informações. De forma automática, é possível a coleta dos
              seguintes dados: informações sobre os computadores, smartphones ou
              outros dispositivos que o usuário utiliza para acessar a
              plataforma, bem como o nome de domínio do seu provedor de acesso à
              internet, o endereço de protocolo IP utilizado para se conectar ao
              Sistema/software CONUBE, o tipo e versão do browser, o sistema
              operacional, dados de falhas fatais, o tempo médio gasto e as
              páginas visitadas no Site, as páginas ou o conteúdo visualizadas,
              as buscas por anúncios, e as páginas acessadas antes ou após
              utilizar nosso site, os horários de acesso e outras estatísticas
              relevantes para o fornecimento de serviço.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Usamos também cookies e outras tecnologias semelhantes como web
              beacons, pixels, ferramentas de análise do navegador, registros do
              servidor e identificadores móveis. Em muitos casos, as informações
              que coletamos usando cookies e outras ferramentas são usadas
              apenas de forma não identificável, sem referência a informações
              pessoais. Sobre a utilização de cookies, detalharemos adiante.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Quando o Cliente demonstra interesse na contratação dos serviços
              da CONUBE, coletamos determinados dados pessoais relativos ao
              próprio Cliente ou do seu representante ou pessoa a ele
              relacionada, tais como: e-mail, nome completo, telefone, CPF/RG e
              endereço. Estes dados são fornecidos pelo titular voluntariamente.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Quando o Cliente realiza a contratação dos serviços da CONUBE,
              para o cadastro é necessário que nos sejam encaminhadas algumas
              informações que podem ser do próprio Cliente, quando este for uma
              pessoa física, ou do seu representante ou pessoa a ele relacionada
              quando se tratar de um Cliente pessoa jurídica, tais como: e-mail,
              nome completo, telefone, CPF/RG, endereço, gênero, naturalidade,
              cargo, participações societárias, profissão e empresa.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Dependendo da natureza dos serviços contratados, poderão ser
              coletados dados relativos aos funcionários, sócios e os
              dependentes destes, fornecedores ou prestadores de serviços dos
              Clientes ou de outros terceiros, tais como: nome, salário,
              PIS/PASEP, dados bancários e financeiros, nº de recibo de
              declaração IR, título de eleitor, etc.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Quando o Usuário demonstra interesse na contratação dos serviços
              da CONUBE, os dados pessoais são coletados para permitir o início
              da interação com a CONUBE, para que se possa ter conhecimento de
              quais são os serviços oferecidos, bem como possibilitar que se
              conheça melhor a própria CONUBE, dando continuidade ao
              relacionamento. Estes tratamentos respeitam o art. 7º, incisos V e
              IX da LGPD.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Quando o Usuário realiza a contratação dos serviços da CONUBE, são
              coletados e tratados dados pessoais essenciais para a prestação
              dos serviços contratados e para o uso do Site, bem como para
              possibilitar o pagamento pelos serviços prestados. Estes
              tratamentos respeitam o art. art. 7º, inciso V da LGPD.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Neste sentido, parte dos dados coletados são dados cadastrais
              relativos ao titular ou vinculados aos dados empresariais, dados
              para a validação e verificação da veracidade das informações
              fornecidas, e parte são dados relativos aos serviços, que são
              inseridos pelo Cliente para a execução dos serviços. Sem estas
              informações não é possível prestar os serviços contratados com
              excelência e de forma segura.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Para usar os recursos da plataforma de pagamento, são igualmente
              tratados dados financeiros, podemos exigir o fornecimento de
              informações financeiras, como dados bancários, número de cartão de
              crédito, dados de faturamento para facilitar o processamento dos
              pagamentos dos serviços contratados, que ocorre através de empresa
              parceira designada para atividades de serviços de cobrança e
              remessa de valores, VINDI.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              A VINDI coleta informações relacionadas às suas transações de
              pagamento, através do Site, incluindo o instrumento de pagamento
              usado, data e hora, valor do pagamento, data de expiração do
              instrumento de pagamento, e outros detalhes da transação. Essas
              informações são necessárias para o cumprimento adequado do
              contrato entre o usuário e a VINDI para permitir a contratação dos
              serviços da CONUBE. Nós coletamos ainda os dados financeiros
              também incluídos no Site, através de importação de nota fiscal
              e/ou no extrato bancário, que possibilita a localização onde a
              nota fiscal é emitida, o banco utilizado pelo Usuário, informações
              sobre a emissão de outro usuário de suas notas fiscais, entre
              outros.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              O Usuário pode optar por nos fornecer dados pessoais sensíveis
              para obter uma melhor experiência ao usar as ferramentas do Site.
              Essas informações adicionais serão processadas com base em nosso
              interesse legítimo ou quando aplicável seu consentimento, do art.
              7º, incisos IX e I da LGPD.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              O Usuário pode escolher importar seus contatos do catálogo de
              endereço ou inserir as informações de seus contatos manualmente
              para acessar certos recursos do Site.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Caso o Usuário opte por usar o serviço de indicações para contar a
              um terceiro sobre os nossos serviços, solicitaremos dados dos
              terceiros indicados como o nome, telefone e o endereço de e-mail
              desse terceiro. O Usuário só deve informar os dados pessoais de
              terceiro se tiver convicção razoável de que ele não obstará o
              nosso contato.O indicado poderá entrar em contato com a CONUBE
              para solicitar a exclusão das suas informações do nosso banco de
              dados.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              A CONUBE pode coletar, recolher, gravar e utilizar em conexão com
              outros sites e parceiros as informações cadastrais, dados que
              terceiros forneçam sobre os usuários do sistema contábil para fins
              de execução dos serviços contratados. No entanto, nós não
              controlamos, supervisionamos ou respondemos pelo modo como
              terceiros fornecem e processam suas informações e, qualquer pedido
              de informação envolvendo a divulgação de seus dados cadastrais.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              De igual maneira a CONUBE poderá apresentar anúncios de terceiros
              e outros conteúdos com links para endereços eletrônicos de
              terceiros. Nestes casos, não seremos responsabilizados por
              práticas de privacidade e conteúdo de terceiros caso o titular
              opte por clicar em um anúncio ou link de terceiros. É possível que
              em razão da execução contratual algum dado pessoal sensível seja
              compartilhado com a CONUBE. Estes tratamentos geralmente respeitam
              o art. art. 11º, incisos I ou II, alíneas “a”, “d” e “g” da LGPD.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Além disso outros tratamentos de dados pessoais podem ser
              realizados, respeitando o art. 7º, inciso IX da LGPD, com o
              objetivo de:
              <ListItem>
                <li>Fornecer, entender, melhorar e desenvolver o Site;</li>
                <li>
                  Criar e manter um ambiente confiável e seguro, bem como
                  cumprir nossas obrigações legais e garantir a segurança dos
                  usuários, Clientes e de quem conosco interage detectando e
                  evitando fraudes, spam, abuso, incidentes de seguranças e
                  outras atividades nocivas;
                </li>
                <li>
                  Personalizar, avaliar e melhorar a publicidade e o marketing
                  para enviar aos usuários mensagens promocionais, informes
                  publicitários e outras informações que possam ser de seu
                  interesse com base em suas preferências;
                </li>
                <li>
                  Administrar programas de indicação, pesquisas ou atividades
                  promocionais como eventos patrocinados e oportunidades
                  relevantes de contabilidade ou de áreas afins;
                </li>
                <li>
                  Conduzir pesquisas e testar recursos em desenvolvimento e
                  gerar relatórios e indicadores relativos às atividades;
                </li>
                <li>
                  Usar as informações para oferecer os serviços de forma
                  personalizada e aprimorando as iniciativas comerciais e
                  promocionais, bem como envio de newsletter;
                </li>
                <li>
                  Administrar os serviços prestados, aperfeiçoá-los e
                  identificar possíveis necessidades dos usuários por meio do
                  envio de e-mails com alertas e comunicados relacionados às
                  funções do sistema, no intuito permitir que o Usuário explore
                  todas as suas funcionalidades.
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              A CONUBE se reserva no direito de realizar o tratamento dos dados
              pessoais do Usuário quando estes forem necessários para o
              cumprimento de obrigação legal ou regulatória, para o exercício
              regular de direitos em processo judicial, administrativo ou
              arbitral, durante todo o prazo necessário para tanto ou com base
              em outras hipóteses legais previstas na LGPD.
            </Typography>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="h3">
              Armazenamento e compartilhamento de dados pessoais
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Nós não divulgamos publicamente nem compartilhamos com terceiros
              dados pessoais, a menos que seja necessário para atender a
              solicitação do Cliente, para nossas necessidades profissionais,
              respeitadas as regras da LGPD, ou conforme exigido ou permitido
              por lei.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Os dados pessoais coletados pela CONUBE serão utilizados e
              armazenados durante o tempo necessário para a prestação do serviço
              ou para que as finalidades apresentadas nessa Política de
              Privacidade sejam atingidas, considerando os direitos dos
              titulares dos dados e dos controladores.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              De modo geral, os seus dados pessoais serão mantidos enquanto a
              relação contratual entre Você e a CONUBE perdurar. Com o fim do
              período de armazenamento dos dados, eles serão excluídos de nossas
              bases de dados ou anonimizados, ressalvadas as hipóteses
              legalmente previstas no artigo 16 da LGPD, a saber:
              <ListItem type="upper-roman">
                <li>
                  cumprimento de obrigação legal ou regulatória pelo
                  controlador;
                </li>
                <li>
                  estudo por órgão de pesquisa, garantida, sempre que possível,
                  a anonimização dos dados pessoais;
                </li>
                <li>
                  transferência a terceiro, desde que respeitados os requisitos
                  de tratamento de dados dispostos na LGPD; ou
                </li>
                <li>
                  uso exclusivo do controlador, vedado seu acesso por terceiro,
                  e desde que anonimizados os dados.
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Ou seja, informações pessoais sobre o Usuário que sejam
              imprescindíveis para o cumprimento de determinações legais,
              judiciais e administrativas e/ou para o exercício do direito de
              defesa em processos judiciais e administrativos serão mantidas, a
              despeito da exclusão dos demais dados.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              O armazenamento de dados coletados pela CONUBE reflete o nosso
              compromisso com a segurança e privacidade dos dados do Usuário.
              Empregamos medidas e soluções técnicas de proteção aptas a
              garantir a confidencialidade, integridade e inviolabilidade dos
              dados pessoais dos Usuários. Além disso, também contamos com
              medidas de segurança apropriadas aos riscos e com controle de
              acesso às informações armazenadas.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Observamos que na prestação dos nossos serviços, o uso de
              subprocessadores dos dados, de parceiros de negócios e de serviços
              de terceiros também poderão levar ao compartilhamento dos dados
              pessoais que tratamos. Os dados pessoais podem ser compartilhados
              com provedores de meios de pagamento, bem como algumas informações
              relevantes para viabilizar a prestação dos serviços aos Clientes
              podem ser compartilhadas com sites do governo, órgãos públicos
              etc. negócios aqui descritos.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Dentre os parceiros de negócios com que podem ser compartilhados
              os dados pessoais, destacamos:
              <ListItem>
                <li>
                  Amazon Web Service (servidor de aplicações e documentos)
                </li>
                <li>Vindi (plataforma de pagamento online)</li>
                <li>eNotas (emissão de notas fiscais)</li>
                <li>Hubspot (CRM)</li>
                <li>Questor (ferramenta contábil)</li>
                <li>Domínio (ferramenta contábil)</li>
                <li>TakeBlip (atendimento ao usuário)</li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Alguns dos terceiros com quem compartilhamos os dados pessoais dos
              Usuários podem ser localizados ou possuir instalações localizadas
              em outros países. Nessas condições, de toda forma, os dados
              pessoais dos Usuários estarão sujeitos à LGPD e às demais
              legislações brasileiras de proteção de dados.
            </Typography>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="h3">Cookies ou dados de navegação</Typography>
            <Typography className={classes.topic} variant="body1">
              A CONUBE faz uso de Cookies, que são arquivos de texto enviados
              pela plataforma ao seu computador e que nele se armazenam, que
              contém informações relacionadas à navegação do site. Em suma, os
              Cookies são utilizados para aprimorar a experiência de uso.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Ao acessar nosso site e consentir com o uso de Cookies, Você
              manifesta conhecer e aceitar a utilização de um sistema de coleta
              de dados de navegação com o uso de Cookies em seu dispositivo.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              A CONUBE utiliza Cookies de sessão. Os cookies de sessão são
              temporários. Quando o Usuário fechar o navegador, o computador
              excluirá todos eles automaticamente.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              O Usuário pode, a qualquer momento e sem nenhum custo, alterar as
              permissões, bloquear ou recusar os Cookies. Mas é importante saber
              que, revogar o consentimento de determinados Cookies, pode
              inviabilizar o funcionamento correto de alguns recursos da
              plataforma.
            </Typography>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="h3">Direitos dos usuários</Typography>
            <Typography className={classes.topic} variant="body1">
              O Usuário pode exercer qualquer um dos direitos descritos nesta
              seção enviando um e-mail para privacidade@conube.com.br, inserindo
              como assunto “Política de Privacidade”.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Os direitos dos Usuários são por nós respeitados e quando atuamos
              como controladora garantimos que a qualquer momento vocês poderão
              solicitar:
              <ListItem type="decimal">
                <li>
                  A confirmação da existência de tratamento de seus dados
                  pessoais;
                </li>
                <li>O acesso aos seus dados pessoais por nós tratados;</li>
                <li>
                  A correção de seus dados pessoais incompletos, inexatos ou
                  desatualizados;
                </li>
                <li>
                  A anonimização, bloqueio ou eliminação de seus dados pessoais
                  considerados desnecessários, excessivos ou tratados em
                  desconformidade com o disposto na Lei Geral de Proteção de
                  Dados;
                </li>
                <li>
                  A portabilidade dos dados pessoais para outro fornecedor de
                  serviço ou produto, mediante requisição expressa, de acordo
                  com a regulamentação da autoridade nacional, observados os
                  segredos comercial e industrial;
                </li>
                <li>
                  A eliminação dos seus dados pessoais tratados com o seu
                  consentimento, exceto nas hipóteses em que o tratamento foi
                  realizado com amparo em base legal diversa do consentimento;
                </li>
                <li>
                  Informações a respeito de com quais entidades públicas e
                  privadas nós realizamos uso compartilhado de dados;
                </li>
                <li>
                  Informações a respeito da possibilidade de não fornecer
                  determinados consentimentos e sobre as consequências da
                  negativa;
                </li>
                <li>A revogação do consentimento.</li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Em caso de impossibilidade de adoção imediata das providências
              indicadas acima, nós enviaremos uma resposta para indicar as
              razões de fato ou de direito que impedem a sua adoção imediata,
              nos termos do art. 18º, § 4º da LGPD.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              O Usuário poderá solicitar o cancelamento de sua conta e/ou
              realizar a exclusão de sua conta a qualquer momento, bastando
              fazê-lo através da plataforma, respeitadas as obrigações
              existentes no contrato de prestação de serviços.
            </Typography>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="h3">Segurança</Typography>
            <Typography className={classes.topic} variant="body1">
              Estamos continuamente implantando e atualizando medidas
              administrativas, técnicas e de segurança física e virtual para
              ajudar a proteger suas informações contra acesso não autorizado,
              perda, destruição ou alteração.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Entre as medidas que adotamos, destacamos as seguintes:
              <ListItem>
                <li>
                  Apenas pessoas autorizadas têm acesso a seus dados pessoais;
                </li>
                <li>
                  O acesso a seus dados pessoais é feito somente após o
                  compromisso de confidencialidade;
                </li>
                <li>
                  Seus dados pessoais são armazenados em ambiente seguro e
                  idôneo.
                </li>
              </ListItem>
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Entretanto, todas essas medidas não eximem que o Usuário, também
              tome as precauções de segurança, tanto guardando a sua senha de
              acesso, quanto se precavendo pela segurança de navegação na
              internet.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Para mantermos suas informações pessoais seguras, usamos
              ferramentas físicas, eletrônicas e gerenciais orientadas para a
              proteção da sua privacidade.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Aplicamos essas ferramentas levando em consideração a natureza dos
              dados pessoais coletados, o contexto e a finalidade do tratamento
              e os riscos que eventuais violações gerariam para os direitos e
              liberdades do titular dos dados coletados e tratados.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Em caso de incidentes de segurança que possa gerar risco ou dano
              relevante para qualquer Usuário, comunicaremos aos afetados e a
              Autoridade Nacional de Proteção de Dados sobre o ocorrido, em
              consonância com as disposições da LGPD.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Se você souber ou tiver motivos para acreditar que suas
              informações cadastrais vinculadas ao Site foram perdidas,
              roubadas, desviadas ou comprometidas, ou em caso de uso não
              autorizado, real ou suspeito, entre em contato conosco pelo e-mail
              privacidade@conube.com.br (Assunto: Perda, roubo, desvio ou
              comprometimento de informações cadastrais).
            </Typography>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="h3">
              Alterações desta política de privacidade
            </Typography>
            <Typography className={classes.topic} variant="body1">
              A CONUBE se reserva ao direito de modificar esta Política de
              Privacidade a qualquer tempo. Para tanto constará do Site a
              Política de Privacidade revisada, constando a data da “Última
              Atualização”.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Adicionalmente será enviada notificação por e-mail ao Cliente
              comunicando a alteração da Política de Privacidade, com pelo menos
              30 (trinta) dias de antecedência. Caso o Usuário não concorde com
              a Política de Privacidade revisada, deverá promover a rescisão
              contratual com a CONUBE, observados os termos do contrato de
              prestação de serviços. O silêncio do Usuário e sua permanência no
              Site, se traduzirá na aceitação da nova Política de Privacidade.
            </Typography>
          </div>
          <div className={classes.paragraph}>
            <Typography variant="h3">Responsabilidade</Typography>
            <Typography className={classes.topic} variant="body1">
              A CONUBE prevê a responsabilidade dos agentes que atuam nos
              processos de tratamento de dados, em conformidade com os artigos
              42 ao 45 da Lei Geral de Proteção de Dados.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Nos comprometemos em manter esta Política de Privacidade
              atualizada, observando suas disposições e zelando por seu
              cumprimento.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Além disso, também assumimos o compromisso de buscar condições
              técnicas e organizativas seguramente aptas a proteger todo o
              processo de tratamento de dados.
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Caso a Autoridade Nacional de Proteção de Dados exija a adoção de
              providências em relação ao tratamento de dados realizado pela
              CONUBE, nos comprometemos a segui-las.
            </Typography>
            <Typography className={clsx(classes.topic, classes.underline)}>
              Isenção de Responsabilidade
            </Typography>
            <Typography className={classes.topic} variant="body1">
              Embora adotemos elevados padrões de segurança a fim de evitar
              incidentes, não há nenhuma página virtual inteiramente livre de
              riscos. Nesse sentido, a CONUBE não se responsabiliza por:
              <ListItem>
                <li>
                  Quaisquer consequências decorrentes da negligência,
                  imprudência ou imperícia dos usuários em relação a seus dados
                  individuais. Garantimos e nos responsabilizamos apenas pela
                  segurança dos processos de tratamento de dados e do
                  cumprimento das finalidades descritas no presente instrumento.
                </li>
                <Typography className={classes.topic} variant="body1">
                  Destacamos que a responsabilidade em relação à
                  confidencialidade dos dados de acesso é do usuário.
                </Typography>
                <li>
                  Ações maliciosas de terceiros, como ataques de hackers, exceto
                  se comprovada conduta culposa ou deliberada da CONUBE.
                </li>
                <Typography className={classes.topic} variant="body1">
                  Destacamos que em caso de incidentes de segurança que possam
                  gerar risco ou dano relevante para você ou qualquer um de
                  nossos usuários/clientes, comunicaremos aos afetados e à
                  Autoridade Nacional de Proteção de Dados sobre o ocorrido e
                  cumpriremos as providências necessárias.
                </Typography>
                <li>
                  Inveracidade das informações inseridas pelo usuário/cliente
                  nos registros necessários para a utilização dos serviços da
                  CONUBE; quaisquer consequências decorrentes de informações
                  falsas ou inseridas de má-fé são de inteiramente
                  responsabilidade do usuário/cliente.
                </li>
              </ListItem>
            </Typography>
          </div>
        </div>
      </ContentWrap>
    </div>
  );
}
