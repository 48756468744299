import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const ListItem = ({ type, children }) => {
  const useStyles = makeStyles({
    root: {
      marginLeft: 16,
      listStyleType: type,
      listStylePosition: 'inside',
      '& > li:first-child': {
        marginTop: 16,
      },
      '& > li': {
        marginBottom: 16,
      },
    },
  });
  const classes = useStyles();

  return <ul className={classes.root}>{children}</ul>;
};

ListItem.propTypes = {
  type: PropTypes.string,
  children: PropTypes.elementType.isRequired,
};

ListItem.defaultProps = {
  type: 'disc',
};

export default ListItem;
